import React from "react";
import "../Sponsors/Sponsor.css";
import { Link } from "react-router-dom";
const Sponsors = () => {
  return (
    <>
      <section className="video-section2">
        <video className="video-background2" autoPlay loop muted>
          <source src="sponsors.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay-6">
          <h5 className="overlay-text2"> Exclusive Sponsorship Opportunities </h5>
          <Link to="/contact" className="contact-button">
            Contact Now
          </Link>
        </div>
      </section>

      <div className="twotext-container">
        <div className="left-2">
          <h5>
            The American Mavericks are a premier cricket team in the Legends
            Intercontinental T-20 League, dedicated to delivering thrilling and
            high-quality cricket. With a focus on exceptional talent and dynamic
            play, we aim to captivate and inspire cricket fans worldwide.
          </h5>
        </div>
        <div className="right-2">
          <p>
            Certainly! Here’s a two-paragraph description for the American
            Mavericks: The American Mavericks are a leading force in the Legends
            Intercontinental T-20 League, bringing a fresh and exhilarating
            approach to cricket. Our team is known for its blend of exceptional
            talent, strategic gameplay, and unwavering determination.
          </p>
          <p>
            Driven by a passion for excellence and a spirit of camaraderie, the
            American Mavericks strive to set new standards in the sport. Each
            player embodies the essence of dedication and skill, contributing to
            our goal of inspiring cricket enthusiasts everywhere.
          </p>
        </div>
      </div>

      <div className="sponsorship-section">
        <h1 className="s-head">Our Sponsors</h1>
        <div
          id="carouselExampleInterval"
          className="carousel slide-s"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval={1000}>
              <div className="slider-main-sp">
                <div className="full-div-img-sp">
                  <div className="div-img-sp">
                    <img src="s1.webp" className="image-slide-sp" alt="..." />
                  </div>
                  <div className="div-img-sp">
                    <img src="s2.webp" className="image-slide-sp" alt="..." />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval={1000}>
              <div className="slider-main-sp">
                <div className="full-div-img-sp">
                  <div className="div-img-sp">
                    <img src="s3.webp" className="image-slide-sp" alt="..." />
                  </div>
                  <div className="div-img-sp">
                    <img src="s1.webp" className="image-slide-sp" alt="..." />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="slider-main-sp">
                <div className="full-div-img-sp">
                  <div className="div-img-sp">
                    <img src="s2.webp" className="image-slide-sp" alt="..." />
                  </div>
                  <div className="div-img-sp">
                    <img src="s3.webp" className="image-slide-sp" alt="..." />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div className="help-section">
        <h1 className="heading-help" data-aos="fade-down">
        Discover Unique Sponsorship Opportunities and Engage with Us!
        </h1>
        <p className="paragraph-help" data-aos="fade-down">
        Adapt them to better suit your content's tone and style!
        </p>
        <Link to="/contact">
          <button className="button-help">Contact Now</button>
        </Link>
      </div>
    </>
  );
};

export default Sponsors;
