import React from 'react'
import '../Navbar/Navbar.css'
import { Link } from 'react-router-dom'
const Navbar = () => {
  return (
    <>
<nav className="navbar">
  <div className="navbar-container container">
    <input type="checkbox" name id />
    <div className="hamburger-lines">
      <span className="line line1" />
      <span className="line line2" />
      <span className="line line3" />
    </div>
    <ul className="menu-items">
      <li><Link to="/">Home</Link></li>
      <li><Link to="/team">Team</Link></li>
      <li><Link to="/sponsors">Sponsors</Link></li>
    
      <li><Link to="/contact">Contact</Link></li>
    </ul>
   
  </div>
</nav>

    </>
  )
}

export default Navbar