// SliderComponent.jsx
import React from 'react';
import Slider from 'react-slick';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Slider/Slider.css';

const Slider1 = () => {
  const NextArrow = ({ onClick }) => (
    <div className="arrow next" onClick={onClick}>
      <FaArrowRight className="logo-a"/>
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="arrow prev" onClick={onClick}>
      <FaArrowLeft className="logo-a"/>
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="slide slide1">
          <div className="overlay">
            <div className="slide-text">Breathtaking catches by American Mavericks</div>
          </div>
        </div>
        <div className="slide slide2">
          <div className="overlay">
            <div className="slide-text">Excitement of dynamic running moments with American Mavericks</div>
          </div>
        </div>
        <div className="slide slide3">
          <div className="overlay">
            <div className="slide-text">Explosive batting performances with American Mavericks</div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Slider1;
