import './App.css';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import Teammain from './Components/Team/Teammain';
import Sponsorsmain from './Components/Sponsors/Sponsorsmain';
import Contactmain from './Components/Contact/Contactmain';
import Chatbox from './Components/Chatbox/Chatbox';
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'  
    });
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Router>
     <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Teammain />} />
        <Route path="/sponsors" element={<Sponsorsmain />} />
        <Route path="/contact" element={<Contactmain />} />
      </Routes>
      <Chatbox/>
    </Router>
  );
}

export default App;
