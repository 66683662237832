import React from 'react'
import '../Video/Videosection.css'
const Videosection = () => {
  return (
    <>
<div className="video-section">
      <video className="video" src="cricket.mp4" autoPlay loop muted />
      <div className="overlay-v">
        <div className="text-v">
        American Mavericks' Arena Where Every Match Becomes Legendary
        </div>
      </div>
    </div>
    </>
  )
}

export default Videosection