import React,{useState} from "react";
import "../Team/Team.css";
import { Link } from "react-router-dom";
const Team = () => {
    const playerData = [
        { id: 1, name: "Player 1", category: "Wicket Keeper", imageUrl: "t.jpg" },
        { id: 2, name: "Player 2", category: "Bowler", imageUrl: "t.jpg" },
        { id: 3, name: "Player 3", category: "All Rounder", imageUrl: "t.jpg" },
        { id: 4, name: "Player 4", category: "Batsman", imageUrl: "t.jpg" },
        { id: 5, name: "Player 1", category: "Wicket Keeper", imageUrl: "t.jpg" },
        { id: 6, name: "Player 2", category: "Bowler", imageUrl: "t.jpg" },
        { id: 7, name: "Player 3", category: "All Rounder", imageUrl: "t.jpg" },
        { id: 8, name: "Player 4", category: "Batsman", imageUrl: "t.jpg" },
        { id: 9, name: "Player 1", category: "Wicket Keeper", imageUrl: "t.jpg" },
        { id: 10, name: "Player 2", category: "Bowler", imageUrl: "t.jpg" },
        { id: 11, name: "Player 3", category: "All Rounder", imageUrl: "t.jpg" },
        { id: 12, name: "Player 4", category: "Batsman", imageUrl: "t.jpg" },
        { id: 13, name: "Player 3", category: "All Rounder", imageUrl: "t.jpg" },
        { id: 14, name: "Player 4", category: "Batsman", imageUrl: "t.jpg" },
       
      ];
    const [selectedCategory, setSelectedCategory] = useState("All");

  const handleButtonClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredPlayers =
    selectedCategory === "All"
      ? playerData
      : playerData.filter((player) => player.category === selectedCategory);

  // Determine the heading text based on the selected category
  const headingText =
    selectedCategory === "All" ? "All Players" : `${selectedCategory}s`;

  return (
    <>
      <div className="founder-section">
        <div className="left-side">
          <h2>Founder Says</h2>
          <p>
          Our mission at Nex Era Sports Pvt Ltd is to push the boundaries of cricket excellence. The American Mavericks, a top contender in the Legends Intercontinental T-20 League, excel with their strategic gameplay and talented roster. With premier facilities and resources, we empower the Mavericks to shine in T-20 cricket. We are proud of their achievements and dedicated to innovation in sports management.
          </p>
        </div>
        <div className="right-side">
          <img src="founder.png" alt="Founder" />
        </div>
      </div>


      <div className="team-section">
        <div className="button-group">
          <button
            className="filter-button"
            onClick={() => handleButtonClick("All")}
          >
            All
          </button>
          <button
            className="filter-button"
            onClick={() => handleButtonClick("Wicket Keeper")}
          >
            Wicket Keepers
          </button>
          <button
            className="filter-button"
            onClick={() => handleButtonClick("Bowler")}
          >
            Bowlers
          </button>
          <button
            className="filter-button"
            onClick={() => handleButtonClick("All Rounder")}
          >
            All Rounders
          </button>
          <button
            className="filter-button"
            onClick={() => handleButtonClick("Batsman")}
          >
            Batsmen
          </button>
        </div>
        <h2 className="section-heading">{headingText}</h2>
        <div className="cards-grid">
          {filteredPlayers.map((player) => (
            <div key={player.id} className="player-card">
              <img
                src={player.imageUrl}
                alt={player.name}
                className="player-image"
              />
              <div className="overlay-3">
                <div className="player-name">{player.name}</div>
                <div className="player-category">{player.category}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      

      <section className="connect-section">
      <div className="background-image-container">
        <img src="connect.jpg" alt="Background" className="background-image" />
      </div>
      <div className="overlay-5">
        <div className="content">
          <h1>Connect with Us</h1>
      
          <h3>Want to learn more about American Mavericks?</h3>
         <Link to="/contact"> <button className="button-24">Contact Now</button></Link>
        </div>
      </div>
    </section>
    </>
  );
};

export default Team;
