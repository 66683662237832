import React from 'react'
import '../Home/Home.css'
import Homepage from '../Homepage/Homepage'
import Navbar from '../Navbar/Navbar'
import Slider from '../Slider/Slider'
import Slider1 from '../Slider/Slider'
import Videosection from '../Video/Videosection'
import Gallery from '../Gallery/Gallery'
import Footer from '../Footer/Footer'
import Footer1 from '../Footer/Footer'
const Home = () => {
  return (
    <>
<div className="home-container">
      <Homepage/>
      <Navbar/>
      <Slider1/>
      <Videosection/>
      <Gallery/>
     <Footer/>
    </div>
    </>
  )
}

export default Home