import React from 'react'
import '../Team/Teammain.css'
import Homepage from '../Homepage/Homepage'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Team from './Team'
const Teammain = () => {
  return (
    <>
      <div className="home-container">
<Homepage/>
<Navbar/>
<Team/>
<Footer/>
      </div>  
    </>
  )
}

export default Teammain