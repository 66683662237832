import React, { useState } from 'react';
import '../Contact/Contact.css';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const Contact = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (name, value) => {
    let errors = {};

    if (name === 'name' && !/^[A-Za-z\s]+$/.test(value)) {
      errors.name = 'Contain only Alphabets';
    }

    if (name === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      errors.email = 'Email address is invalid';
    }

    if (name === 'phone' && !/^\d{10}$/.test(value)) {
      errors.phone = 'Phone number must be 10 digits';
    }

    if (name === 'message' && value.length < 15) {
      errors.message = 'At least 15 characters long';
    }

    setFormErrors(errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    validate(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Check for form errors before sending
    if (Object.keys(formErrors).length === 0 && Object.values(formValues).every(value => value !== '')) {
      emailjs.sendForm('service_ssxkboc', 'template_u8npaho', e.target, 'do5_Nv_EhkP_X4FJE')
        .then((result) => {
          Swal.fire({
            icon: 'success',
            title: 'Form submitted successfully!',
            showConfirmButton: false,
            timer: 1500
          });
          setIsSubmitting(false);
          setFormValues({
            name: '',
            email: '',
            phone: '',
            message: ''
          });
        }, (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Failed to submit the form.',
            showConfirmButton: false,
            timer: 1500
          });
          setIsSubmitting(false);
        });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Please fix the errors in the form.',
        showConfirmButton: false,
        timer: 1500
      });
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="poster-co">
        <img 
          src="https://img.freepik.com/premium-photo/cricketer-batsman-hitting-shot-match_1271820-493.jpg?w=996" 
          alt="Poster" 
          className="poster-image-co" 
        />
      </div>

      <div className="cont-section">
        <div className="cont-card card-1">
          <h5 className="card-heading">Join the American Mavericks</h5>
          <p className="card-text2">Be a part of our journey. Connect with us to get the latest updates and news about the American Mavericks cricket team</p>
        </div>
        <div className="cont-card card-2">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input 
                type="text" 
                autoComplete='off' 
                id="name" 
                name="name" 
                value={formValues.name} 
                onChange={handleChange} 
                required 
              />
              {formErrors.name && <p className="error">{formErrors.name}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input 
                type="email" 
                autoComplete='off' 
                id="email" 
                name="email" 
                value={formValues.email} 
                onChange={handleChange} 
                required 
              />
              {formErrors.email && <p className="error">{formErrors.email}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input 
                type="tel" 
                autoComplete='off' 
                id="phone" 
                name="phone" 
                value={formValues.phone} 
                onChange={handleChange} 
                required 
              />
              {formErrors.phone && <p className="error">{formErrors.phone}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea 
                id="message" 
                autoComplete='off' 
                name="message" 
                value={formValues.message} 
                onChange={handleChange} 
                required 
              />
              {formErrors.message && <p className="error">{formErrors.message}</p>}
            </div>
            <button type="submit" className="button-26" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </div>
        <div className="cont-card card-3">
          <p className="card-text">Follow Us</p>
          <div className='socio'>
            <a href='#' className="social-media"><FaFacebook className='ic' /> </a>
            <a href='#' className="social-media"><FaTwitter className='ic' /> </a>
            <a href='#' className="social-media"><FaInstagram className='ic' /> </a>
            <a href='#' className="social-media"><FaLinkedinIn className='ic' /> </a>
          </div>
          <p className='p-t1'>Email:<br/>info@americanmavericks.us</p>
         {/*- <p className='p-t2'>mobile:+91 00000000000</p>
          <p className='p-t3'>Address:123 Main St, XYZ, UP, India</p>
          -*/}
        </div>
      </div>

      <div id="map" className="responsive-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46830151.11795828!2d-119.8093025!3d44.24236485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1722836479083!5m2!1sen!2sin"
          width={600}
          height={450}
          frameBorder={0}
          style={{ border: 0 }}
          allowFullScreen
        />
      </div>
    </>
  );
};

export default Contact;
