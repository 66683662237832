import React from 'react'
import '../Sponsors/Sponsorsmain.css'
import Homepage from '../Homepage/Homepage'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Sponsors from './Sponsors'
const Sponsorsmain = () => {
  return (
    <>
<div className="home-container">
<Homepage/>
<Navbar/>
<Sponsors/>
<Footer/>
</div>
    </>
  )
}

export default Sponsorsmain