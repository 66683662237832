import React, { useEffect, useRef } from 'react';
import '../Gallery/Gallery.css';

const Gallery = () => {
  const galleryRef = useRef(null);
  const time = 10000;

  const animStart = () => {
    if (galleryRef.current && !galleryRef.current.classList.contains('active')) {
      galleryRef.current.classList.add('active');
      setTimeout(() => {
        animEnd();
      }, time);
    }
  };

  const animEnd = () => {
    if (galleryRef.current) {
      galleryRef.current.classList.remove('active');
      forceReflow(galleryRef.current); // Trigger reflow
    }
  };

  const forceReflow = (element) => {
    if (element) {
      element.style.display = 'none'; // Temporarily hide the element
      
      element.style.display = ''; // Restore the element's visibility
    }
  };

  useEffect(() => {
    const handleScroll = () => animStart();
    const handleResize = () => animStart();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    animStart(); // Initial call

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <main>
      <div id="gallery" ref={galleryRef}>
        <figure>
          <img 
            src="https://img.freepik.com/free-photo/world-cup-cricket-player-background_1409-6235.jpg?t=st=1722602488~exp=1722606088~hmac=6a389eee7965a1a74402b52c69efcb7f1ed33bc05f7b115d98445f669c80a1a0&w=740" 
            alt="Pink and blue clouds at sunset."
            title="Photo by Jeremy Doddridge for Unsplash" 
          />
          <figcaption>Thunder Shots</figcaption>
        </figure>
        <figure>
          <div>
            <div>
              <h2>American Mavericks</h2>
              <p>Our Gallery</p>
              <p><br /><small>Where Every Match Becomes Legendary <br /><a target="_blank" className='link-g' rel="noopener noreferrer" href="#">Check Out </a></small></p>
            </div>
          </div>
        </figure>
        <figure>
          <img 
            src="https://qph.cf2.quoracdn.net/main-qimg-7b675e88385f69a16bdb5793047143a6-lq" 
            alt="Low angle view up into a sky filled with deep blue clouds." 
            title="Photo by Matthew Brayer for Unsplash" 
          />
          <figcaption>Catching</figcaption>
        </figure>
        <figure>
          <img 
            src="https://img.freepik.com/premium-photo/cricketer-field-action_1162225-24481.jpg?uid=R154760312&ga=GA1.1.1899534357.1689522783&semt=ais_hybrid" 
            alt="Heavy gray clouds in the sky." 
            title="Photo by Simeon Muller for Unsplash" 
          />
          <figcaption>Running Goal</figcaption>
        </figure>
        <figure>
          <img 
            src="https://img.freepik.com/free-photo/close-up-baseball-ball_23-2150885069.jpg?t=st=1722602724~exp=1722606324~hmac=f252473e62a3ac0cde7f8fa8983becb2e92e1888443df4a9f491170f21283f9f&w=740" 
            alt="Deep orange clouds at sunset." 
            title="Photo by Laura Vinck for Unsplash" 
          />
          <figcaption>Burning Ball</figcaption>
        </figure>
        <figure>
          <img 
            src="https://img.freepik.com/premium-photo/cricket-fitness-bands-players-focusing-mobility_1247965-30596.jpg?w=740" 
            alt="Clouds in shades of dark blue and magenta at sunset." 
            title="Photo by Szabo Viktor for Unsplash" 
          />
          <figcaption>Test Time</figcaption>
        </figure>
        <figure>
          <img 
            src="https://img.freepik.com/premium-photo/stadium-with-sunset-background-sign-that-says-national-stadium_1003790-5308.jpg?uid=R154760312&ga=GA1.1.1899534357.1689522783&semt=ais_hybrid" 
            alt="Clouds in shades of blue and gold at sunrise." 
            title="Photo by Łukasz Łada for Unsplash" 
          />
          <figcaption>Stadium View</figcaption>
        </figure>
        <figure>
          <img 
            src="https://img.freepik.com/free-photo/purple-cricket-player-background_1409-6220.jpg?t=st=1722602576~exp=1722606176~hmac=af1882400e8a6525cdee72c0ce386dce4b4c5ea33d27968142c69ebe1bd12dd1&w=740" 
            alt="clouds and sky during daytime" 
            title="Photo by Diego PH for Unsplash" 
          />
          <figcaption>Cool Dress up</figcaption>
        </figure>
     
       
      
       
      </div>
    </main>
  );
};

export default Gallery;
