import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import '../Footer/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src="logo.png" className='l' alt="American Mavericks" />
        </div>
        <div className="footer-links">
          <Link to="/">Home</Link>
          <Link to="/team">Team</Link>
          <Link to="/sponsors">Sponsors</Link>
          <Link to="/contact">Contact</Link>
        </div>
        <div className="footer-social">
          <a href="#" className="social-icon"><FaFacebookF /></a>
          <a href="#" className="social-icon"><FaSquareXTwitter /></a>
          <a href="#" className="social-icon"><FaInstagram /></a>
          <a href="#" className="social-icon"><FaLinkedinIn /></a>
        </div>
      </div>
      <div className="footer-bottom">
        <p> Designed @ 2024 by <a href="https://infogainsoft.com" target="_blank" rel="noopener noreferrer">Infogainsoft</a></p>
      </div>
    </footer>
  );
};

export default Footer;
