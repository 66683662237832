import React, { useEffect, useState } from 'react';
import '../Homepage/Homepage.css';

const Homepage = () => {
  const [spinClass, setSpinClass] = useState('');

  useEffect(() => {
    // Add the class to spin the logo when the component mounts
    setSpinClass('spin-on-load');

    // Remove the class after animation is done
    const timer = setTimeout(() => {
      setSpinClass('');
    }, 1000); // Match the duration of the animation

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="homepage">
        <div className="logo-poster">
          <img src="logo.png" alt="Logo" className={`logo-image ${spinClass}`} />
          <p className="logo-text">American Mavericks</p>
        </div>
      </div>
    </>
  );
}

export default Homepage;
