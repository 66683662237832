import React from 'react'
import '../Contact/Contactmain.css'
import Homepage from '../Homepage/Homepage'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Contact from './Contact'
const Contactmain = () => {
  return (
    <>
    <div className="home-container">
        <Homepage/>
        <Navbar/>
       <Contact/>
        <Footer/>
    </div>
    </>
  )
}

export default Contactmain